.loaderContainer {
  width: 100%;
  padding: 150px 0 150px;
  display: flex;
  justify-content: center;

  .large-select-0 {
    fill: #BE9600;
  }

  .large-select-1 {
    fill: #181818;
  }

  .large-select-2 {
    fill: #363636;
  }
}

.loadingContainer {
  width: 100%;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background: white;
  -webkit-animation: animationName 2s infinite;
  -moz-animation: animationName 2s infinite;
  -o-animation: animationName 2s infinite;
  animation: animationName 2s infinite;
  padding: 20px 0;

  img {
    max-height: 290px;
    max-width: 290px;

    filter: grayscale(100%);
  }
}


@keyframes animationName {
  0% {
    opacity: .2;
  }
  50% {
    opacity: .8;
  }
  100% {
    opacity: .2;
  }
}

@-o-keyframes animationName {
  0% {
    opacity: .2;
  }
  50% {
    opacity: .8;
  }
  100% {
    opacity: .2;
  }
}

@-moz-keyframes animationName {
  0% {
    opacity: .2;
  }
  50% {
    opacity: .8;
  }
  100% {
    opacity: .2;
  }
}

@-webkit-keyframes animationName {
  0% {
    opacity: .2;
  }
  50% {
    opacity: .8;
  }
  100% {
    opacity: .2;
  }
}

