@import '../../../../../styles/base/base-colors.scss';
@import '../../../../../styles/base/base-typography.scss';



.contentContainer{
  .headerImg{
    width: 100px;
    //height: 20px;
  }
}

