@import '../../../styles/base/base-colors.scss';
@import '../../../styles/base/base-typography.scss';
@import '../../../styles/base/base-layout.scss';

.searchBoxContainer{
    display: grid;
    align-items: center;
    align-content: center;
    right: 20px;
    top: 25px;
}
