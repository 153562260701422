@import '/../../../assets/fonts/MyFontsWebfontsKit.css';
@import url('https://fonts.googleapis.com/css?family=Antic|Lato:300,400,700&display=swap');
//@import './base-layout.scss';

//FONT FACES
$primaryFont: 'CoreSansA45Regular';
$secondaryFont: 'GalanoGrotesque-SemiBold';
$tertiaryFont: 'CoreSansA65Bold';
$quaternaryFont: 'CoreSansA25ExtraLight';
$quinaryFont: 'Albra-BlackItalic';
$senaryFont: 'CoreSansA55Medium';

$articleFont: 'CoreSansA35Light';
$captionFont: 'CoreSansA45Regular';


$secondaryFullFont: 300 14px Lato, serif;

//FONT SIZES
$bodyFontSize: 14px;
$primaryBodyFontSize:14px;
$inputFontSize:16px;

//HEADER
$fullHeaderFont: 400 13px $secondaryFont, serif;

//FOOTER
$fullFooterFont: 300 13px $primaryFont, serif;

:export {
  fullHeaderFont: $fullHeaderFont;
  tertiaryFont:$tertiaryFont;
}
