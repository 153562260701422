@import 'styles/mixins/mixins-typography.scss';
@import 'styles/mixins/mixins-layout.scss';
@import 'styles/base/base-layout.scss';
@import 'styles/base/base-colors.scss';
@import 'components/poster-tiles/poster-tiles.scss';

html{
  height: 100%;
  //display: grid;
  //overflow-x:hidden ;

  body{
    height: 100%;
    overflow-x:hidden ;
    &.dark-mode{
      background-color: #373737 !important;
    }

    @include primaryBodyFont;
    #root{
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

    }
    .alternateBackground{
      @include alternateBackground;
    }

    .content {
      flex: 1 0 auto;

      h1,h2,h3,h4,h5,h6{
        &.tinyTextTitle{@include tinyTextTitle;}
        &.tinyTextTitle-a{@include tinyTextTitle(center, $tertiaryHeadingColor);}

        &.mediumTextTitle{@include mediumTextTitle;}

        &.largeTextTitle{@include largeTextTitle;}
        &.largeTextTitle-a{@include largeTextTitle(center, $tertiaryHeadingColor );}
        &.largeTextTitle-m{@include largeTextTitle(center, '', 20px 0 );}

        &.semiLargeTextTitle{@include semiLargeTextTitle(center, #222222);}
        &.semiLargeTextTitle-a{@include semiLargeTextTitle(center, $tertiaryHeadingColor );}

        &.smallTextTitle{@include smallTextTitle(center,10px 0);}

        &.subTitle{@include subTitle(left, 0);}
        &.subTitle-m{
          @include subTitle(center,0);
          padding:10px 0;
        }
        &.subTitle-poster{
          @include subTitle(center,0);
          padding: 10px 0 !important;
          color: #27522d;
          border-radius: 4px;
        }

        &.sectionTitle{@include sectionTitle;}
        &.sectionText{@include sectionText;}
      }
      .pageContainer{
        @include pageContainer;
      }
      i{
        color:$iconActive;
      }

    }
    .footer {
      align-content: flex-end;
      margin-top: auto
    }
  }
}
