.load-fade-in {
  opacity: 0; }

.load-fade-in.visible {
  padding: 100px 0;
  transition: opacity 0.6s ease-in;
  opacity: 1; }

.box {
  margin-top: 5px; }
