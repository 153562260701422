@import '../../../styles/base/base-colors.scss';
@import '../../../styles/base/base-typography.scss';
@import '../../../styles/base/base-layout.scss';

//logo
$logoHeight: 65px;
.linksContent {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  margin-left: 10px;
  justify-self: flex-end;
}

.header {
  color: white;
  padding: 17px 6px;
  position: relative;
  background-color: $headerBackgroundColor;
  display: flex;
  justify-content: flex-start;
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: $websitePageWidth;

    .logoContainer {
      justify-content: flex-start;

      a {
        color: #FFF;
        text-decoration: none;
        height: 100%;
        display: block;

        #logo, #logoMobile {
          height: $logoHeight;
          display: flex;
          @media screen and (max-width: 562px) {
            height: auto;
            width: 300px;
          }
        }

        #logoMobile {
          display: none;
        }
      }

      .active {
        .tkas {
          &::before {
            color: #27a3bf;
          }
        }
      }
    }

    .linksContainer {
      justify-content: center;
      //background-color: $linkBlockBackgroundColor;
      padding: 0 20px;


      .linksTilesContainer {
        height: 100%;
      }

    }
  }
}

@media screen and (max-width: $websitePageWidth) {

  .linksContainer, {
    display: none;
  }
}

@media screen and (max-width: 990px) {

  .linksContent {
    display: none;
  }
}

@media screen and (max-width: $tabletLargePageWidth) {
  .header {


    .headerContent {
      .logoContainer {
        a {
          //#logo {
          //    display: none;
          //}
          //#logoMobile {
          //    display: flex;
          //    height: $logoHeight;
          //}
        }
      }
    }
  }
  .ReactModal__Overlay {
    top: $headerOffset;
  }
}

@media screen and (max-width: $mobilePageWidth) {
  .topRow {
    display: none;
  }
}

