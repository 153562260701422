@import '../../../../styles/base/base-colors.scss';
@import '../../../../styles/base/base-typography.scss';
@import '../../../../styles/base/base-layout.scss';

$color: $secondaryThemeColor;
//background colors
//$headerBackgroundColor: #f9f9f9;

//button params
$height-button:46px;
$width-button: 52px;
$buttonBorderRadius:5px;
$buttonBackgroundColor:#f5f5ff;

//icon params
$height-icon: 18px;
//line params
$width-line: 32px;
$height-line: 4px;
$lineColor:#95a5a6;
$lineHoverColor:#95a5a6;

//animation params
$transition-time: 0.4s;
$rotation: 45deg;
$translateY: ($height-icon / 2);
$translateX: 0;

//icon offset params
$topOffset:12px;
$leftOffset:10px;
$rightOffset:10px;

//modal transition speed
$modalTransition: opacity 800ms ease-in-out;

//#menuButton{
  //height: $height-button;
  //width: $width-button;
  //position: absolute;
  ////right: $rightOffset;
  //left: $leftOffset;
  //top: $topOffset;
  //overflow: hidden;
  ////background-color: $buttonBackgroundColor;
  //border-radius: $buttonBorderRadius;
  ////display: flex;
  //align-items: center;
  //justify-content: center;
  //display: none;
  //cursor: pointer;
  //z-index: 2;
  ////transform:scale(0.6);
  //
  //#hamburger-icon {
  //  width:$width-line;
  //  height:($height-icon);
  //  position:relative;
  //  //fixes vertical align
  //  top:-($height-line /2);
  //  display:block;
  //
  //  .line {
  //    display:block;
  //    background:$color;
  //    width:$width-line;
  //    height:$height-line;
  //    position:absolute;
  //    left:0;
  //    border-radius:($height-line / 2);
  //    transition: all $transition-time;
  //    -webkit-transition: all $transition-time;
  //    -moz-transition: all $transition-time;
  //
  //    &.line-1 {
  //      top:0;
  //    }
  //    &.line-2 {
  //      top:50%;
  //    }
  //    &.line-3 {
  //      top:100%;
  //    }
  //  }
  //  &:hover, &:focus {
  //    .line-1 {
  //      transform: translateY($height-line / 2 * -1);
  //      -webkit-transform: translateY($height-line / 2 * -1);
  //      -moz-transform: translateY($height-line / 2 * -1);
  //    }
  //    .line-3 {
  //      transform: translateY($height-line / 2);
  //      -webkit-transform: translateY($height-line / 2);
  //      -moz-transform: translateY($height-line / 2);
  //    }
  //  }
  //  &.active {
  //    .line-1 {
  //      transform: translateY($translateY) translateX($translateX) rotate($rotation);
  //      -webkit-transform: translateY($translateY) translateX($translateX) rotate($rotation);
  //      -moz-transform: translateY($translateY) translateX($translateX) rotate($rotation);
  //    }
  //    .line-2 {
  //      opacity:0;
  //    }
  //    .line-3 {
  //      transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
  //      -webkit-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
  //      -moz-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
  //    }
  //  }
  //}
  //
  //h1 {
  //  text-transform:uppercase;
  //}
  //a {
  //  text-decoration:none;
  //  color:$lineColor;
  //  //margin: 0.5em 1.5em;
  //  display:inline-block;
  //  &:hover, &:focus {
  //    color:$primaryLinkHoverColor;
  //  }
  //}
//}

.ReactModal__Overlay{
  opacity: 0;
  transition: $modalTransition;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.modalContent2{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  //WebkitOverflowScrolling: 'touch';
  padding: 20px;
  background-color: $headerBackgroundColor;
}

.modalContent2:focus {
  box-shadow: none;
  outline: none;
}

.ReactModal__Overlay{
    position: fixed;
   top: $headerOffset;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0,0,0,0.75);
  display: none ;
  z-index: 3;
}

@media screen and (max-width: $websitePageWidth)
{
  .ReactModal__Overlay{
    display: block;
  }
}


