@import './base-typography.scss';
@import './base-colors.scss';


//page properties
$websitePageWidth: 1310px;
$laptopSmallPageWidth: 1160px;
$tabletLargePageWidth: 768px;
$tabletPageWidth: 768px;
$mobilePageWidth: 450px;
$mobileMenuWidth:210px;


//poster properties
$posterPadding:64px;
$posterWrap:wrap;
$posterAlign:center;

//header offset
$headerOffset: 0;

//change this - temporary
$tileFlexWidth:190px;

//todo dont do this, dont import typo and colors in here, change this to a mixin
.bodyFlexContainer{
  display: flex;
  justify-content: center;
  width: $websitePageWidth;
  margin: auto;


  .tilesFlex{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    &>div{
      width: $tileFlexWidth;
      margin: 10px;
      padding:10px;
      background-color: $generalBackgroundColor;
      text-align: center;
      font:$secondaryFullFont;
    }

  }

}

@media screen and (max-width: $mobilePageWidth)
{
  html{
    body{
      #root{
        position: relative;
        //top: 74px;
        top: $headerOffset;
        .content{

          .header{
            justify-content: flex-start;
            position: fixed;
            right: 0;
            left: 0;
            top: 0;
            z-index: 11;

            .headerContent{
              position: relative;
              left: 0;
            }
          }
        }
      }

    }
  }

}

@media screen and (max-width: $websitePageWidth) {
  .bodyFlexContainer{
    width: 80%;
  }
}

:export {
  websitePageWidth: $websitePageWidth;
  headerOffset:$headerOffset;
  mobileMenuWidth:$mobileMenuWidth
}
