

.desktopSearch{

    @media screen and (max-width: 1200px) {
        display: none;
    }
}
.mobileSearch{
    display: none;
    @media screen and (max-width: 1200px) {
        display: flex;

    }
    .MuiOutlinedInput{
        width: 100%;
    }
}
.searchField{
    width: 100% !important;
}
