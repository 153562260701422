@import '../../styles/base/base-layout.scss';
@import '../../styles/mixins/mixins-layout.scss';
@import '../../styles/base/base-colors.scss';


//base variables
$smallTileWidth: 160px;
$standardTileWidth:260px;
$standardGridPadding:40px;

$standardGripGap:40px;
$tabletGripGap:20px;
$largeTileWidth:380px;
$maxTileWidth:500px;

//adding fat to max-width so we dont have gaps of awkwardness
$responsiveFat:12px;

//generic grid layout used in gridTiles
@mixin gridLayout($verticalAlign:center){
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: $verticalAlign;
  align-items: $verticalAlign;
}

@mixin gridTiles( $tileWidth, $verticalAlign:center, $paddingOverride:$standardGridPadding, $gridGapOverride:$standardGripGap,$maxTileWidth:1fr){
  @include gridLayout($verticalAlign);
  grid-gap: $gridGapOverride;
  grid-template-columns:repeat(auto-fit, minmax($tileWidth, $maxTileWidth) );
  padding: $paddingOverride;
  grid-auto-flow:row dense;
  justify-items: center;
  justify-content: center;
  & div{
    text-align:center;
    width: 100%;
    height: 100%;
    align-items: center;
    display: grid;
    font-size: 19px;
    & img{
      max-width: 100%;
      max-height: 365px;
      width: auto !important;
      height: auto !important;
    }
    figure{
      width: 100%;
      margin:0;
      padding:0;
    }
  }

  //squeeze 6 to 4 - bypass 5 columns
  @media (max-width: $tileWidth * 6 + ($gridGapOverride * 5) + ($paddingOverride * 2)+ $responsiveFat) {
    >div:nth-child(5){
      grid-column: 1/3;
      grid-row: 2;
    }
    >div:nth-child(6){
      grid-column: 3/5;
      grid-row: 2;;
    }
  }
  //squeeze 4 to 2 -bypass 3 columns
  @media (max-width: $tileWidth * 4 + ($gridGapOverride * 3) + ($paddingOverride * 2) + $responsiveFat) {
    >div:nth-child(3){
      grid-column: 1/2;
      grid-row: 2;
    }
    >div:nth-child(4){
      grid-column: 2/3;
      grid-row: 2;
    }
    >div:nth-child(5){
      grid-column: 1/2;
      grid-row: 3;
    }
    >div:nth-child(6){
      grid-column: 2/3;
      grid-row: 3;
    }
  }
  @media (max-width: $tileWidth * 2 + ($gridGapOverride) + ($paddingOverride * 2) + $responsiveFat) {
    grid-template-columns:1fr;
    >div{
      grid-column: 1/2 !important;
    }
    >div:nth-child(3){
      grid-row: 3;
    }
    >div:nth-child(4){
      grid-row: 4;
    }
    >div:nth-child(5){
      grid-row:5;
    }
    >div:nth-child(6){
      grid-row: 6;
    }
  }

  @include rsp(tablet) {
    padding: 80px 15px;
    grid-gap:$tabletGripGap;
    row-gap: 80px;
    grid-template-columns:repeat(auto-fit, minmax($tileWidth, 1fr) );
    max-height: none;
  }
  @include rsp(phone) {
    padding: 10px 20px;
  }


}

.gridTiles{
  @include gridTiles($standardTileWidth);
}
.gridTiles-t{
  @include gridTiles($standardTileWidth,flex-start);
}

.gridTilesSmall-t{
  @include gridTiles($smallTileWidth,flex-start);
}

.gridTilesLarge{
  @include gridTiles($largeTileWidth,center,10px, 20px, $maxTileWidth);
  @include rsp(tablet) {
    padding: 10px;
    grid-template-columns: 1fr;
    >div{
      grid-column: 1/2;
    }
  }
}
.gridTilesArticle{
  @include gridTiles($standardTileWidth,left);
  & div{
    text-align:left;
    width: 100%;
    font-size: 16px;
    line-height: 1.5rem;
    & img{
      width: 100%;
    }
    h1,h2,h3,h4,h5,h6{
      font-size: 20px;
    }
  }
}

.iconTitleTextContainer{
  display: flex;
  justify-content: space-evenly;
  .tile{
    display: flex;
    flex-direction: column;
    padding: 32px;
    img {
      &.circlePic{
        border-radius: 50%;
      }
    }
    &>div{
      flex-wrap: wrap;
      max-width: 350px;
      text-align: center;
      a{
        color:$primaryLinkColor;
        text-decoration: none;
        &:visited {
          color: $primaryLinkColor;
        }
        &:hover {
          color:  lighten($primaryLinkColor, 20%);
          text-decoration: none;
        }
        &:active {
          color:  lighten($primaryLinkColor, 20%);
        }
      }
    }
    .icon{

    }
    .title{

    }
    .text{

    }
  }
}

//tablet breakpoint
@media (max-width: $tabletPageWidth) {

  .iconTitleTextContainer{
    flex-direction: column;
    .tile{
      div{
        max-width: 100% !important;

      }
      .icon{

      }
      .title{

      }
      .text{

      }
    }
  }
}
